import React from "react"
import {Link} from "gatsby"
import SEO from "../components/SEO"

const PageNotFound = () => {
    return (
        <div>
            <SEO title="Error"/>
            <h1>Whoops! Page not found</h1>
            <p>
                <Link to="/">Click here to head back home</Link>
            </p>
        </div>
    )
}
export default PageNotFound